@keyframes gradientPan {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.basic-gradient,
.dynamic-gradient {
  --gradient-color-1: #ef008f;
  --gradient-color-2: #6ec3f4;
  --gradient-color-3: #7038ff;
  --gradient-color-4: #ffba27;
  --gradient-animation-speed: 15s;

  height: 100%;
  width: 100%;
  flex: 1;
}

.basic-gradient {
  background: linear-gradient(
    -45deg,
    var(--gradient-color-1),
    var(--gradient-color-2),
    var(--gradient-color-3),
    var(--gradient-color-4)
  );

  background-size: 400% 400%;

  &.animating {
    animation: gradientPan var(--gradient-animation-speed) ease infinite;
  }
}

.dynamic-gradient {
  canvas {
    height: 100%;
    width: 100%;
    z-index: 0;
  }
}
