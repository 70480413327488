$h1: 6.4rem;
$h2: 4.9rem;
$h3: 3.7rem;
$h4: 2.8rem;
$h5: 2.1rem;
$h6: 1.6rem;
$p: 1.8rem;
$p-small: 1.4rem;
$p-large: 2.2rem;

$heading-family: "Caveat", cursive, sans-serif;
$font-family: "Red Hat Display", Arial, Helvetica, sans-serif;

body {
  font-family: $font-family;
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--text);
  min-height: 100vh;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
.h1 {
  font-size: $h1;
}
h2,
.h2 {
  font-size: $h2;
}
h3,
.h3 {
  font-size: $h3;
}
h4,
.h4 {
  font-size: $h4;
}
h5,
.h5 {
  font-size: $h5;
}
h6,
.h6 {
  font-size: $h6;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 1.2;
  font-weight: 400;
  color: var(--heading-text);
  font-family: $heading-family;
}

p,
.p {
  font-size: $p;
  line-height: 2;
  letter-spacing: 0.01em;

  &.small {
    font-size: $p-small;
  }

  &.large {
    font-size: $p-large;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.p {
  &.white {
    color: var(--white);
  }

  &.black {
    color: var(--black);
  }

  &.primary {
    color: var(--primary);
  }

  &.light {
    font-weight: 300;
  }

  &.bold {
    font-weight: 700;
  }
}

.branded-title {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: var(--primary);
  font-size: 3.6rem;
  font-family: "Pirou", "Raleway", Arial, Helvetica, sans-serif;
}
