// Color Variables
$branding: #5c7387;
$primary: #6eaf6e;
$jet: #333333;
$white: rgb(247, 247, 245);
$black: #212121;
$light-gray: #f9f9f9;
$charcoal: #5c5c5c;

$text: #444f5c;
// $text: #333;
$placeholder: #999999;

$error: #d45555;
$success: #80cf9b;

:root {
  --primary: #{$primary};
  --branding: #{$branding};
  --jet: #{$jet};
  --white: #{$white};
  --black: #{$black};
  --light-gray: #{$light-gray};
  --charcoal: #{$charcoal};

  --text: #{desaturate(lighten($text, 15%), 12%)};
  --heading-text: #{$text};
  --placeholder: #{$placeholder};

  --error: #{$error};
  --success: #{$success};

  --active-gradient-accent-primary: #ffffffc3;
  --active-gradient-accent-secondary: #fff;
}
