.text-cycle {
  width: 100%;
  height: 12rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__item {
    text-align: center;
    width: 100%;
    position: absolute;
    font-family: $heading-family;
    font-size: 4.8rem;
    line-height: 1.1;

    @include phone {
      font-size: 3.6rem;
    }

    span {
      display: inline-block;

      &.space {
        padding: 0;
      }
    }

    &.system-message {
      font-family: "Inconsolata", monospace;
      font-size: 1.4rem;
      letter-spacing: 0;
    }
  }

  &__word {
    opacity: 0;
    padding: 0.12em;
  }
}
