.branding {
  padding: var(--base-spacing-unit);
  margin-top: spacing(5);

  @include ipad {
    margin-top: spacing(3);
  }

  @include phone {
    margin-top: spacing();
  }

  &__name {
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-weight: 700;
    color: desaturate(darken($branding, 15%), 10%);

    span {
      display: inline-block;
    }
  }

  &__dope {
    color: lighten($branding, 5%);
  }
}
