.story {
  flex: 1;
  display: flex;

  &__container {
    flex: 1;
    height: 100%;
    position: relative;
    display: flex;
  }

  // Setup Screen Styling
  &__setup {
    padding: spacing(8) spacing(5);
  }

  &__modal {
    --story-modal-text-color: var(--white);

    padding: spacing(10) spacing(8);
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: spacing(4);

    &.dark {
      --story-modal-text-color: var(--jet);
    }

    @include phone {
      padding: spacing(5) spacing(4);
    }

    &-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    &-emoji {
      font-size: 8rem;
      margin-top: spacing(12);
      text-align: left;
      width: 100%;

      @include phone {
        margin-top: spacing(4);
        font-size: 6.4rem;
      }
    }

    &-title {
      font-family: $font-family;

      margin-bottom: spacing(4);
      color: var(--story-modal-text-color);
      opacity: 0.8;
      font-weight: 700;
      font-size: 8rem;
      text-align: left;
      width: 100%;

      @include phone {
        font-size: 6.4rem;
        line-height: 1.1;
        margin-bottom: spacing(2);
      }
    }

    &-text {
      line-height: 1.6;
      font-size: 2.8rem;
      font-weight: 600;
      color: var(--story-modal-text-color);
      opacity: 0.8;
      text-align: left;

      @include phone {
        font-size: 2.4rem;
        line-height: 1.5;
      }
    }

    &-actions {
      display: flex;
      gap: spacing(2);

      &.single {
        justify-content: center;
        max-width: 36rem;
      }
    }
  }

  // Slideshow styling

  &__slides {
    position: relative;
    display: flex;
    flex: 1;
    z-index: 1;
  }

  &__touch-targets {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;

    display: flex;
  }

  &__touch-target {
    height: 100%;

    &--back {
      flex: 35;
    }

    &--next {
      flex: 65;
    }
  }

  &__animation-assets {
    position: fixed;
    height: 8rem;
    width: 8rem;

    top: 50vh;
    left: 50vw;
    z-index: 100;
    transform: translate(-50%, -50%);
    @include flexCenter;

    pointer-events: none;

    --obj-color: var(--white);

    &.light {
      --obj-color: var(--jet);
    }

    &.dark {
      --obj-color: var(--white);
    }

    &.primary {
      --obj-color: var(--jet);
    }
  }

  &__pointer {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--obj-color);
    border-radius: 50%;
    transition: background $smooth ease-in-out 0.5s;

    opacity: 0;
  }

  &__ripple {
    position: absolute;
    height: 250vh;
    width: 250vh;
    background-color: var(--obj-color);
    border-radius: 50%;
    transition: background $smooth ease-in-out 0.5s;

    opacity: 0;
    transform: scale(0);
  }

  &__audio {
    display: none;
  }

  &__progress {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    padding: spacing(2);
    gap: spacing(0.75);
    z-index: 100;
  }

  &__indicator {
    flex: 1;
    height: spacing(1);
    border-radius: var(--radius);
    overflow: hidden;
    transition: background $smooth ease-in-out;

    span {
      display: block;
      height: 100%;
      width: 100%;
      background-color: var(--white);
      transform-origin: left;
      transform: scaleX(0);
      transition: background $smooth ease-in-out;
    }

    .light & {
      background-color: rgba($white, 20%);
      span {
        background-color: $white;
      }
    }

    .dark & {
      background-color: rgba($jet, 20%);
      span {
        background-color: $jet;
      }
    }

    .primary & {
      background-color: rgba($primary, 20%);
      span {
        background-color: $primary;
      }
    }
  }

  &__mute,
  &__playback {
    position: absolute;
    cursor: pointer;
    top: spacing(5.5);
    right: spacing(2);
    z-index: 100;
    height: spacing(10);
    width: spacing(10);
    border-radius: 50%;
    @include flexCenter;
    transition: background $smooth ease-in-out, color $smooth ease-in-out;

    .icon.icon-xl {
      --icon-size: #{spacing(4.75)};
    }

    &.light {
      background-color: rgba($white, 20%);
      color: var(--white);

      &.active {
        background-color: var(--white);
        color: var(--jet);
      }
    }

    &.dark {
      background-color: rgba($jet, 20%);
      color: var(--jet);

      &.active {
        background-color: var(--jet);
        color: var(--white);
      }
    }

    &.primary {
      background-color: rgba($primary, 20%);
      color: var(--primary);

      &.active {
        background-color: var(--primary);
        color: var(--black);
      }
    }
  }

  &__playback {
    right: spacing(14);
  }
}
