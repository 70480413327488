.modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  height: var(--padded-doc-height);
  width: 100%;

  @include flexCenter;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(black, 0.25);
    // @include phone {
    //   background-color: transparent;
    //   backdrop-filter: brightness(40%) contrast(50%) blur(8px);
    // }
  }

  &__container {
    position: relative;
    height: var(--app-container-height);
    aspect-ratio: 9 / 16;
    overflow: hidden;
    border-radius: radius(2);

    @include flexCenter;

    @include phone {
      height: 100%;
      width: 100%;
      aspect-ratio: unset;
      border-radius: 0;
    }
  }

  &__window {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;

    // @include phone {
    //   width: calc(100% - (var(--base-spacing-unit) * 4));
    // }
  }
}
