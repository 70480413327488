@keyframes magic {
  50% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(90deg);
  }
}

@keyframes power {
  100% {
    transform: rotate(360deg);
  }
}

.snowflake-loader {
  --loader-size: 8rem;
  box-sizing: content-box;
  height: calc(var(--loader-size) * 2.5);
  width: var(--loader-size);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__layer {
    &--0 {
      fill: #08d0e9;
    }
    &--1 {
      fill: #08579a;
    }
    &--2 {
      fill: #088ae8;
    }
    &--3 {
      fill: #157cb3;
    }
    &--4 {
      fill: #0ed1ea;
    }
    &--5 {
      fill: #0c8ce8;
    }
    &--6 {
      fill: #0085e7;
    }
    &--7 {
      fill: #58a6ea;
    }
  }

  svg {
    height: var(--loader-size);
    padding-bottom: calc(var(--loader-size) * 0.25);
    position: absolute;
    transform-origin: 50% 100%;
    animation: magic 6s linear infinite alternate;
    mix-blend-mode: color;
    will-change: filter;
  }

  path {
    transform-origin: 50% 130%;
    animation: power 3s infinite cubic-bezier(0.77, 0, 0.175, 1);
    will-change: transform;
  }

  @for $i from 1 through 8 {
    svg:nth-child(#{$i}) {
      transform: rotate($i * 45deg);
      animation-delay: $i * 0.1s;
    }
  }
}
