.progress-bar {
  width: 100%;
  padding: spacing(3);
  display: flex;
  transform-origin: center;

  &__bar {
    flex: 1;
    height: spacing();
    display: flex;
    background-color: rgba($primary, 0.25);
    overflow: hidden;
    border-radius: spacing();
  }

  &__inner {
    flex: 1;
    align-self: stretch;
    background-color: $primary;
    transform: scaleX(0);
    transform-origin: left;
  }
}
