.icon {
  --icon-size: #{spacing(3)};
  --icon-color: currentColor;

  height: var(--icon-size);
  width: var(--icon-size);
  color: var(--icon-color);

  &-xs {
    --icon-size: #{spacing(2)};
  }

  &-sm {
    --icon-size: #{spacing(2.5)};
  }

  &-md {
    --icon-size: #{spacing(3)};
  }

  &-lg {
    --icon-size: #{spacing(3.5)};
  }

  &-xl {
    --icon-size: #{spacing(4)};
  }

  &-xxl {
    --icon-size: #{spacing(5)};
  }

  &.white {
    --icon-color: var(--white);
  }

  &.jet {
    --icon-color: var(--jet);
  }

  &.black {
    --icon-color: var(--black);
  }

  &.primary {
    --icon-color: var(--primary);
  }

  &.slate {
    --icon-color: var(--slate);
  }

  &.slate-dark {
    --icon-color: var(--slate-dark);
  }

  &.slate-light {
    --icon-color: var(--slate-light);
  }

  &.placeholder {
    --icon-color: var(--placeholder);
  }
}
