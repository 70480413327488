@keyframes preload-spin {
  0% {
    border-radius: 50%;
    transform: rotate(0);
    background-position: 0 50%;
  }
  20% {
    border-radius: 50%;
    transform: rotate(0);
  }
  50% {
    background-position: 100% 50%;
  }
  90% {
    border-radius: 5%;
    transform: rotate(90deg);
  }
  100% {
    border-radius: 5%;
    transform: rotate(90deg);
    background-position: 0 50%;
  }
}

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  background-color: var(--white);

  --preloader-base-size: 20rem;
  --preloader-size: var(--preloader-base-size);
  --preloader-gradient: linear-gradient(
    45deg,
    #337aff,
    #572ff3,
    #e81f6c,
    #f1c726
  );

  &__circle {
    width: var(--preloader-size);
    height: var(--preloader-size);
    border-radius: 50%;
    animation: preload-spin 1.5s alternate infinite;
    position: absolute;

    @for $i from 1 to 9 {
      &:nth-child(#{$i}) {
        animation-delay: $i * 0.075s;
        background: var(--preloader-gradient);
        background-size: 200%;
        --preloader-size: calc(var(--preloader-base-size) - (#{$i} * 2rem));
      }
    }
  }

  &__invisible-text {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
}
