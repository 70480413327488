.input-form {
  --input-font-size: 2.4rem;
  --input-letter-spacing: 0.125em;
  --input-opacity: 0.75;
  --input-background: rgba(255, 255, 255, 1);
  --input-text-color: var(--heading-text);
  --input-height: calc(var(--input-font-size) * 2.8);

  background-color: var(--input-background);
  width: 100%;
  height: var(--input-height);
  border-radius: var(--radius);
  display: flex;
  transition: background $dt;
  overflow: hidden;

  &.pin {
    --input-font-size: 4rem;
    --input-letter-spacing: 0.25em;
    max-width: 44rem;

    @include ipad {
      --input-font-size: 2.8rem;
      max-width: 32rem;
    }
  }

  &.error {
    --input-text-color: var(--error);
  }

  &:focus-within {
    --input-background: #fff;
    --input-opacity: 1;
  }

  &__input {
    display: flex;
    flex: 1;

    input {
      border: none;
      text-align: center;
      font-size: var(--input-font-size);
      background: none;
      display: block;
      height: 100%;
      width: 100%;
      letter-spacing: var(--input-letter-spacing);
      opacity: var(--input-opacity);
      transition: opacity $dt, color $dt;
      color: var(--input-text-color);

      &:focus {
        outline: none;
      }
    }
  }

  &__submit {
    width: var(--input-height);
    display: flex;
  }
}
