.slide {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;

  &.current {
    z-index: 100;
  }

  &.next {
    z-index: 10;
  }

  &__audio {
    display: none;
  }

  &__template {
    position: relative;
    display: flex;
    flex: 1;
  }
}

.template {
  &-video {
    display: flex;
    flex: 1;
    overflow: hidden;
    position: relative;

    &__player {
      position: absolute;
      height: calc(100% + var(--radius));
      width: calc(100% + var(--radius));
      top: calc(var(--radius) / -2);
      left: calc(var(--radius) / -2);
      display: flex;
    }

    &__video {
      flex: 1;
      object-fit: cover;
    }
  }

  &-title-sequence {
    @include flexCenter;
    flex: 1;
    padding: spacing(10) spacing(6);
    text-align: center;
    font-size: 2.5rem;
    line-height: 1.15;
    font-weight: 600;
    color: var(--text-color);
    background-color: var(--bg-color);
    border-radius: var(--radius);
    overflow: hidden;
    position: relative;

    @include phone {
      padding: spacing(6) spacing(2);
    }

    &__content {
      flex: 1;
      width: 100%;
      position: relative;
      @include flexCenter;
    }

    &__title {
      position: absolute;
      width: calc(100% - 2 * var(--base-spacing-unit));
      z-index: 10;

      h2 {
        font-family: $font-family;
        font-weight: 700;
        font-size: 6.4rem;
        line-height: 1.5;
        color: var(--active-gradient-accent-primary);

        @include ipad {
          font-size: 6rem;
        }

        @include phone {
          font-size: 5.6rem;
          padding: spacing();
        }

        span {
          display: inline-block;

          &.emoji {
            font-size: 12rem;
            display: block;
            padding-bottom: spacing(4);
            color: var(--active-gradient-accent-secondary);

            @include phone {
              font-size: 10rem;
            }
          }

          &.alt {
            color: var(--active-gradient-accent-secondary);
          }

          &.alt-font {
            font-family: $heading-family;
            font-size: 8.4rem;

            & > span.word {
              padding: 0.12em;
            }

            @include ipad {
              font-size: 7.2rem;
            }

            @include phone {
              font-size: 6.4rem;
            }
          }
        }
      }
    }

    &__background {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 0;
      display: flex;
    }
  }

  &-memories {
    flex: 1;
    @include flexCenter;

    &__background {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 0;
      display: flex;
    }

    &__content {
      flex: 1;
      position: relative;
      width: 100%;
      @include flexCenter;
    }

    &__memory {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: spacing(3);
      max-width: calc(100% - (4 * var(--base-spacing-unit)));
    }

    &__frame {
      border-radius: var(--radius);
      overflow: hidden;
      box-shadow: var(--soft-shadow);

      img {
        width: 100%;
        max-height: 48vh;
        object-fit: cover;
        object-position: center;
      }

      video {
        width: 100%;
        max-height: 48vh;
        object-fit: cover;
        object-position: center;
        display: block;
      }
    }

    &__caption {
      display: flex;
      justify-content: center;

      p {
        font-size: 2.8rem;
        font-weight: 600;
        color: var(--active-gradient-accent-primary);
        text-align: center;
        line-height: 1.4;

        span {
          display: inline-block;
          padding: 0.1em;

          &.alt {
            color: var(--active-gradient-accent-secondary);
          }

          &.alt-font {
            font-family: $heading-family;
          }
        }
      }
    }
  }

  &-polaroids {
    flex: 1;
    @include flexCenter;

    &__background {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 0;
      display: flex;
    }

    &__content {
      flex: 1;
      position: relative;
      width: 100%;
      @include flexCenter;
      padding: spacing(12) spacing(4) 0;

      @include phone {
        padding: spacing(2) spacing(2) 0;
      }
    }

    &__polaroid {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: spacing(3);
      max-width: calc(100% - (12 * var(--base-spacing-unit)));
      background-color: white;
      padding: spacing(8) spacing(3) spacing(4);
      border-radius: 4px;

      @include phone {
        max-width: calc(100% - (9 * var(--base-spacing-unit)));
        padding: spacing(4) spacing(1.5) 0;
        gap: 0;
      }
    }

    &__frame {
      border-radius: 2px;
      overflow: hidden;
      box-shadow: var(--soft-shadow);

      img {
        width: 100%;
        max-height: 48vh;
        object-fit: cover;
        object-position: center;
      }

      video {
        width: 100%;
        max-height: 60vh;
        object-fit: cover;
        object-position: center;
        display: block;
      }
    }

    &__caption {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 spacing(2);
      min-height: spacing(12);

      p {
        font-family: $heading-family;
        font-size: 4rem;
        font-weight: 600;
        color: var(--jet);
        text-align: center;
        line-height: 1.1;

        @include phone {
          padding: spacing(2);
          font-size: 3.4rem;
        }

        span {
          display: inline-block;

          &.alt {
            color: var(--active-gradient-accent-secondary);
          }

          &.alt-font {
            font-family: $heading-family;
          }
        }
      }
    }
  }
}
