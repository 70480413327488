.login {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;
    padding: spacing(8) spacing(5) spacing(4);

    @include phone {
      padding: spacing() spacing(2) spacing(2);
    }
  }

  &__illustration {
    padding: 0 spacing(4);

    @include phone {
      padding: 0 spacing(2);
    }

    &-container {
      max-width: 40rem;
      max-height: 40rem;
      margin: 0 auto;

      @include ipad {
        max-width: 28rem;
        max-height: 28rem;
      }
    }
  }

  &__title {
    text-align: center;
    font-size: 8rem;

    @include ipad {
      font-size: 6.4rem;
    }

    &--recipient {
      font-size: 4.8rem;
      color: var(--primary);

      @include ipad {
        font-size: 3.6rem;
      }
    }
  }

  &__header {
    margin-bottom: spacing(6);
    @include phone {
      margin-bottom: spacing(4);
    }
  }

  &__label {
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 2.4rem;

    @include ipad {
      margin-bottom: 2rem;
      font-size: 2rem;
    }
  }

  &__form {
    align-self: stretch;
    display: flex;
    flex-direction: column;
  }

  &__cta {
    @include flexCenter;
  }

  &__error {
    display: flex;
    margin-top: spacing(2.5);

    opacity: 0;
    pointer-events: none;
    transition: opacity $dt;

    &.visible {
      opacity: 1;
    }

    svg {
      margin-top: 2px;
      margin-right: spacing(1.5);
      color: var(--error);
    }

    p {
      line-height: 1.5;
      font-weight: 600;
    }
  }

  &__footer {
    text-align: center;
    padding: spacing(4);
    position: relative;

    @include ipad {
      padding: spacing(2);
    }

    &-copy {
      font-weight: 700;
      color: var(--heading-text);
      font-family: $heading-family;
      font-size: 4rem;
      position: relative;

      @include ipad {
        font-size: 3.2rem;
      }
    }
  }

  &__branding {
    display: flex;
    justify-content: center;
  }

  &__input {
    @include flexCenter;
  }
}
