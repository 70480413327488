$soft-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08),
  0px 0px 12.5px rgba(0, 0, 0, 0.06), 0px 0px 25px rgba(0, 0, 0, 0.04);

$app-shadow: 1.5px 1.5px 2.7px rgba(0, 0, 0, 0.016),
  3.9px 3.9px 6.9px rgba(0, 0, 0, 0.022), 8px 8px 14.2px rgba(0, 0, 0, 0.028),
  16.4px 16.4px 29.2px rgba(0, 0, 0, 0.034), 45px 45px 80px rgba(0, 0, 0, 0.05);

:root {
  --soft-shadow: #{$soft-shadow};
  --app-shadow: #{$app-shadow};
}
