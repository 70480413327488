body {
  --padded-doc-height: var(--doc-height);
  height: var(--padded-doc-height);

  &.locked {
    overflow: hidden;
    --padded-doc-height: calc(var(--doc-height) + 2px);
  }

  --app-container-height: 90%;
  @include respond-y(60em) {
    --app-container-height: 94%;
  }
}

.app {
  height: var(--padded-doc-height);
  width: 100vw;
  position: relative;

  @include flexCenter;

  @include phoneOnly {
    // background-color: var(--white);
  }

  &__container {
    display: flex;
    position: relative;
    height: var(--app-container-height);
    aspect-ratio: 9 / 16;

    background-color: var(--white);
    border-radius: radius(2);
    box-shadow: $app-shadow;

    overflow: hidden;

    @include phoneOnly {
      height: 100%;
      width: 100%;
      aspect-ratio: unset;
      border-radius: 0;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    height: 100%;
    width: 100%;

    &-layer {
      height: 100%;
      width: 100%;

      position: absolute;
      top: 0;
      left: 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      // Background Themes
      &--snowflakes {
        background-color: var(--branding);
        img {
          opacity: 0.1;
        }
      }

      &--gradient {
        background-color: #0093e9;
        background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
      }

      &--cosmic-gradient {
        background-color: #09203f;
        background-image: linear-gradient(
          to top left,
          #09203f 0%,
          #537895 100%
        );
      }
    }
  }
}
