.loading-screen {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    padding: spacing(6) spacing(3);
  }

  &__illustration {
    @include phone {
      padding: 0 spacing(4);
    }

    @include iphone {
      padding: 0 spacing(2);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: spacing(4);

    @include phone {
      gap: spacing(0);
    }
  }

  &__loader {
    max-width: 48rem;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @include phone {
      max-width: 44rem;
    }

    @include phone {
      max-width: 40rem;
    }
  }

  &__progress {
    position: relative;
    @include flexCenter;
  }

  &__load-message {
    position: absolute;
    text-align: center;

    p {
      font-weight: 600;
      font-size: 0.96em;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      color: desaturate(darken($branding, 15%), 10%);
      span {
        display: inline-block;
        opacity: 0;
        font-weight: 700;
      }
    }
  }

  &__title {
    margin: spacing() 0 0;
    text-align: center;
    color: var(--primary);
  }

  &__subtitle {
    text-align: center;
  }

  &__text-cycle {
    width: 100%;
  }
}

.snow-globe {
  .ai {
    transition: opacity $dt;
  }

  &__plants {
    opacity: 0;
  }

  &.animated &__plants {
    opacity: 1;
    animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) svgFadeIn;
    animation-delay: 0s;
  }

  &.animated &__snow {
    animation: 3s Infinite linear svgFloating;
    animation-delay: 0s;
  }

  &.animated &__tree {
    animation: 3s Infinite linear svgWind;
    animation-delay: 0s;
  }

  &.animated &__snowman {
    animation: 1.5s Infinite linear svgWind;
    animation-delay: 0s;
  }

  &.animated &__gifts {
    animation: 3s Infinite linear svgWind;
    animation-delay: 0s;
  }
}

.snowman {
  .ai {
    transition: opacity $dt;
  }

  &.animated &__snow {
    animation: 3s Infinite linear svgShake;
    animation-delay: 0s;
  }

  &.animated &__snowman {
    animation: 1.5s Infinite linear svgWind;
    animation-delay: 0s;
  }
}

.genie {
  .ai {
    transition: opacity $dt;
  }

  &__background,
  &__floor {
    opacity: 0;
  }

  &.animated &__background {
    animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) svgFadeIn;
    animation-delay: 0s;
  }

  &.animated &__floor {
    animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) svgFadeIn;
    animation-delay: 0s;
  }
  &.animated &__genie {
    animation: 1.5s Infinite linear svgFloating;
    animation-delay: 0s;
  }
  &.animated &__plant {
    animation: 3s Infinite linear svgWind;
    animation-delay: 0s;
  }
  &.animated &__stars {
    animation: 3s Infinite linear svgFloating;
  }
}
