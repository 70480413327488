.welcome-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  &__branding {
    display: flex;
    justify-content: center;

    &--condensed {
      .branding {
        margin: 0;
        padding: spacing(2);
      }
    }
  }

  &__container {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--white);
    padding: spacing(4) spacing(6);
    gap: spacing(3);

    @include phone {
      gap: spacing(2);
      padding: 0 spacing(2) spacing(2);
    }
  }

  &__title {
    margin: spacing() 0 0;
    text-align: center;
    font-family: $font-family;
    font-size: 6.4rem;
    font-weight: 700;
    color: var(--text-color);
    margin-bottom: spacing(2);

    @include phone {
      font-size: 4.8rem;
      line-height: 1.1;
      margin-bottom: 0;
    }

    span {
      color: var(--primary);
    }

    &--emoji {
      font-size: 8rem;

      @include ipad {
        font-size: 6.4rem;
      }

      @include phone {
        font-size: 5.2rem;
      }
    }
  }

  &__subtitle {
    text-align: center;
    font-size: 2.4rem;
    font-weight: 600;
    color: var(--text-color);
    padding: spacing(4) spacing(4) 0;
    line-height: 1.6;

    @include phone {
      font-size: 2rem;
      padding: spacing(3) 0 0;
    }
  }

  &__instructions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: flex-end;
    flex: 3;
  }

  &__tutorial {
    flex: 4;
    display: flex;
  }

  &__submit {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    &--center {
      justify-content: center;
    }
  }

  &__next {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 8rem;
    width: 8rem;
  }

  &__sound {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 3;
    position: relative;
    padding: spacing(4);

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;

    &:focus {
      outline: none;
    }

    &-label {
      font-weight: 600;
      font-size: 2.8rem;
      color: darken(desaturate($primary, 15%), 15%);
      opacity: 0.8;
    }
  }

  &__rings {
    flex: 1;
    max-width: 28rem;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: lighten($primary, 20%);
    position: relative;
    transition: background 0.25s ease;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;

    @include ipad {
      max-width: 24rem;
    }

    @include phone {
      max-width: 22rem;
    }

    &:focus {
      outline: none;
    }

    cursor: pointer;
    @include flexCenter;

    &.animating {
      background-color: $primary;
    }
  }

  &__ring {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 8px solid $primary;
    z-index: -1;
    pointer-events: none;
  }

  &__caption {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 8rem;
    font-size: 2.4rem;
    font-weight: 600;
  }
}
