@mixin link {
  &,
  &:link,
  &:visited {
    @content;
  }
}

@mixin uppercase {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

@mixin flexCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin flexGap($gap: 1.2rem, $direction: "row") {
  & > *:not(:last-child) {
    @if $direction == "row" {
      margin-right: $gap;
    } @else if $direction == "column" {
      margin-bottom: $gap;
    } @else if $direction == "row-reverse" {
      margin-left: $gap;
    } @else if $direction == "column-reverse" {
      margin-top: $gap;
    }
  }
}

@mixin blockGap($gap: 1.2rem) {
  & > *:not(:last-child) {
    margin-bottom: $gap;
  }
}

@mixin overlay {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
}

@mixin pseudo {
  display: block;
  content: "";
  position: absolute;
}

@mixin cover {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@mixin absoluteFull {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
}

@mixin flex-center($direction: column) {
  display: flex;
  flex-direction: $direction;
  justify-content: center;
  align-items: center;
}

// A mixin to use dynamic font sizing based on CSS clamp
@mixin dynamic-font(
  $min-font-size,
  $max-font-size,
  $fluid-start: 60,
  $fluid-end: 140,
  $unit: "rem"
) {
  // A great guide on fluid typography. Calculations based off of this article
  // https://www.smashingmagazine.com/2022/01/modern-fluid-typography-css-clamp/

  // Calculate ideal font size
  $fluid-variable-font-size: (100 * ($max-font-size - $min-font-size)) /
    ($fluid-end - $fluid-start);

  // Calculate font constant
  $fluid-constant-font-size: (
      ($fluid-start * $max-font-size) - ($fluid-end * $min-font-size)
    ) / ($fluid-start - $fluid-end);

  // Set the minimum font-size as a fallback value
  font-size: $min-font-size;

  // Set preferred clamp font size
  font-size: clamp(
    #{$min-font-size}#{unit},
    #{$fluid-variable-font-size}vw + #{$fluid-constant-font-size}#{$unit},
    #{$max-font-size}#{$unit}
  );
}

// Create a surface with dynamic shadowns and color based on simulated lighting
@mixin surface($elevation: 1) {
  background-color: var(--surface-background-color);
  box-shadow: var(--standard-shadow);
}

@mixin gapX($spacing: var(--base-spacing-unit)) {
  & > *:not(:last-child) {
    margin-right: $spacing;
  }
}

@mixin gapY($spacing: var(--base-spacing-unit)) {
  & > *:not(:last-child) {
    margin-bottom: $spacing;
  }
}

// A quick function for scaling things
@function scaleVar($custom-property, $factor) {
  @return calc(var(#{$custom-property}) * $factor);
}

@function spacing($units: false) {
  @if $units {
    @return calc(var(--base-spacing-unit) * $units);
  }
  @return var(--base-spacing-unit);
}

@function radius($units: false) {
  @if $units {
    @return calc(var(--base-radius-unit) * $units);
  }
  @return var(--base-radius-unit);
}
