*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  // font-size: 62.5%;
  // Using smaller base size htan usual because this is a mobile first application
  font-size: 50%; //1rem = 9px
  scroll-behavior: smooth;

  --doc-height: 100vh;
  --track-width: 120rem;
  --max-content-width: 56rem;
  --x-pad: 10rem;
  --y-pad: 20rem;
  --radius: 0.4rem;

  @include laptop {
    --y-pad: 16rem;
    --x-pad: 8rem;
  }

  @include ipadPro {
    // font-size: 56.25%; //1rem = 9px
    --y-pad: 14rem;
    --x-pad: 7rem;
  }

  @include ipad {
    // font-size: 50%; //1rem = 8px
    --y-pad: 12rem;
    --x-pad: 6rem;
  }

  //  Height Media Queries
  @include respond-y(47em) {
    --y-pad: 12rem;
    --x-pad: 6rem;
  }

  @include phone {
    --y-pad: 12rem;
    --x-pad: 2rem;
  }

  @include iphone {
    font-size: 43.75%; //1rem = 7px;
    --y-pad: 10rem;
    --x-pad: 1.2rem;
  }
}

body {
  box-sizing: border-box;
  overflow-x: hidden;
  width: 100vw;
  background-color: var(--white);

  &.fixed {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: fixed;
  }
}

label,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="tel"],
select,
textarea {
  display: block;
  &:focus {
    outline: none;
  }
}

button {
  font-family: $font-family;
}

input,
textarea {
  font-family: $font-family;
  font-size: 1.8rem;
  color: var(--jet);
  &::placeholder {
    color: var(--placeholder);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

::placeholder {
  color: var(--placeholder);
}

::selection {
  background: rgba($primary, 0.6);
}

ul {
  list-style: none;
}

a,
a:link,
a:visited {
  text-decoration: none;
  color: var(--primary);
  cursor: pointer;
  transition: color $dt, background-color $dt, opacity $dt, box-shadow $dt,
    border $dt;

  &:hover {
    opacity: 0.75;
  }
}

img,
svg {
  display: block;
}

hr {
  display: block;
  border: none;
}

video {
  max-width: 100%;
}

*:not(.allow-select) {
  user-select: none;
}
