.tutorial {
  display: flex;
  flex: 1;

  --screen-ratio: calc(9 / 16);
  --screen-padding: #{spacing(3)};

  padding: 0;

  &__container {
    display: flex;
    justify-content: center;
    flex: 1;
  }

  &__animation {
    height: 100%;
    width: auto;
    aspect-ratio: var(--screen-ratio);
    position: relative;
    display: flex;
    border-radius: 0.8rem;
    overflow: hidden;

    @include phone {
      aspect-ratio: unset;
      width: 100%;
      height: 100%;
    }
  }

  &__screen {
    position: relative;
    flex: 1;

    &-slide {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;

      @include flexCenter;

      &--start {
        padding: spacing(6) spacing(4);
        background: linear-gradient(
          to top right,
          lighten($branding, 20%),
          lighten($branding, 35%)
        );

        h2 {
          text-align: center;
          line-height: 1.15;
          flex: 2;
          span {
            color: darken($primary, 20%);

            span {
              animation: 1s blink infinite;

              &:nth-child(2) {
                animation-delay: 0.2s;
              }

              &:nth-child(3) {
                animation-delay: 0.3s;
              }
            }
          }
        }
      }

      &--end {
        video {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &__overlay {
    &,
    &s {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }

    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &-darken {
      background-color: rgba(black, 40%);
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }

    &-text {
      position: relative;
      padding: spacing(2) spacing(2) spacing(4);
      font-size: 2.4rem;
      height: 40%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background-image: linear-gradient(to top, rgba(black, 40%), transparent);

      p {
        color: var(--white);
        font-weight: 600;
        font-size: 1.25em;
        line-height: 1.5;
        text-align: center;

        span {
          display: inline-block;
        }
      }
    }

    &-touch {
      --touch-highlight: transparent;

      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      overflow: hidden;

      @include flexCenter;

      &--next {
        width: 65%;
        right: 0;
        left: unset;
        --touch-highlight: #{rgba($primary, 40%)};
      }

      &--back {
        width: 35%;
        --touch-highlight: #{rgba($primary, 40%)};
      }

      &-highlight {
        position: absolute;
        height: 100vh;
        width: 100vh;
        border-radius: 50%;
        background: var(--touch-highlight);
      }

      &-icon {
        height: 3.6rem;
        width: 3.6rem;
        border-radius: 50%;
        background-color: $white;
        position: relative;
      }
    }
  }
}
