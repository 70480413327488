.button {
  --btn-text: var(--white);
  --btn-color: var(--black);
  --btn-size: 8rem;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: spacing(2.5) spacing(4);
  width: 100%;

  user-select: none;

  position: relative;
  overflow: hidden;

  font-weight: 600;

  @include link {
    color: var(--btn-text);
  }

  border: none;
  background-color: var(--btn-color);
  border-radius: var(--radius);

  cursor: pointer;
  transition: filter $dt;

  &,
  & * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  &__text {
    font-size: 2rem;
    line-height: 1;
    letter-spacing: 0.05em;
  }

  hr {
    height: spacing(3);
    width: 2px;
    background-color: var(--btn-text);
    opacity: 0.25;
  }

  &__loader {
    --loader-dot-size: #{spacing()};

    @include overlay;
    display: flex;
    align-items: center;
    justify-content: center;
    @include flexGap(var(--loader-dot-size));
    background-color: var(--btn-color);
    transition: opacity $dt;
    opacity: 1;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    span {
      display: block;
      height: var(--loader-dot-size);
      width: var(--loader-dot-size);
      background-color: var(--btn-text);
      border-radius: 50%;
      animation: dotFlashing 0.8s infinite alternate;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }

  &:not(.secondary):hover {
    filter: opacity(75%);
  }

  &.primary {
    --btn-color: var(--primary);
    --btn-text: var(--jet);
    font-weight: 700;

    &.light {
      --btn-text: var(--jet);
      --btn-color: var(--white);
    }

    &.dark {
      --btn-text: var(--white);
      --btn-color: var(--jet);
    }
  }

  &.secondary {
    --btn-color: transparent;
    --btn-text: var(--jet);
    font-weight: 700;

    border: 2px solid var(--jet);

    transition: background $dt, color $dt;

    &:hover {
      --btn-color: var(--jet);
      --btn-text: var(--white);
    }

    &.light {
      --btn-text: var(--white);
      border-color: rgba($white, 80%);

      &:hover {
        --btn-color: var(--white);
        --btn-text: var(--jet);
        border-color: var(--white);
      }
    }
  }

  &.charcoal {
    --btn-color: var(--charcoal);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    background-color: #a4a4a4;
  }

  &.loading {
    pointer-events: none;
  }

  &.fit-content {
    width: max-content;
  }

  &.square {
    padding: 0;
  }

  &.circle {
    height: var(--btn-size);
    width: var(--btn-size);
    border-radius: 50%;
    padding: 0;

    &.lg {
      --btn-size: 10rem;

      @include ipad {
        --btn-size: 8rem;
      }
    }

    &.xl {
      --btn-size: 12rem;

      @include ipad {
        --btn-size: 10rem;
      }
    }
  }

  &.xl &__loader {
    --loader-dot-size: #{spacing(1.5)};
  }

  &.text {
    background: none;
    --btn-text: currentColor;
    font-weight: 700;
    letter-spacing: 0;
    width: max-content;
    padding: spacing(2) spacing();
  }
}
